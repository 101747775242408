import React, {useState, useEffect} from "react";
import Trunks from "./Trunks";
import AllocatedNumbers from "./AllocatedNumbers/AllocatedNumbers";
import {Header} from "./styled";
import {Icon} from "rsuite";
import {DEFAULT_PER_PAGE_SMALL, transformed_service_reverse_data} from "const";
import {getManagedServices} from "utils";
import { usePrevious } from "hooks";



export default (
    {
        ivr_list,
        trunks = [],
        service,
        account,
        authInfo,
        accountJoinList,
        isMobile,
        activeTab,
        accountId,
        trunksInited,
        trunksLoading,
        currentTrunkId,
        currentRangeNumber,
        
        raterc_list,
        isSupervisorSms,
        allocatedNumbers,
        allocatedNumbersPage,
        allocatedNumbersCount,
        allocatedNumbersPerPage,
        allocatedNumbersLoading,
        allocatedNumbersTotalCount,
        accountServicePlanList,

        trunkDownloadNumbers,

        getIvrList,
        getAccountTrunk,
        setCurrentTrunkId,
        unsetTrunksInited,
        setCurrentRangeNumber,
        getTrunkNumberCsvById,
        getAccountAllocatedNumbers,
    }
) => {

    const prevService = usePrevious(service);

    const [showAllocatedNumbersModal, setShowAllocatedNumbersModal] = useState(false);
    const [showClosedTrunks, setShowClosedTrunks] = useState(false);
    const [numbersTableReadonly, setNumbersTableReadonly] = useState(false);
    const [numbersParams, setNumbersParams] = useState({});

    useEffect(() => {
        const managedServiceList = getManagedServices(authInfo);
        const transformedService = transformed_service_reverse_data[service];

        if (managedServiceList.includes(transformedService)) {
            if (!trunks.length) {
                getTrunks();
            }
        
            // if (prevService === service) -- trial
            //     getTrunks();

            // if (!trunks.length || prevService !== service) -- old one
            //     getTrunks();
            
            if (!!service)
                getIvrList();
        }
    }, [service]);

    useEffect(() => {
        if (trunks.length && !currentTrunkId) {
            const filteredTrunks = trunks.filter(trunk => !trunk.closed || showClosedTrunks);

            setCurrentTrunkId(filteredTrunks[0]?.id)
        } 
    }, [trunks]);

    useEffect(() => {
        const managedServiceList = getManagedServices(authInfo);
        const transformedService = transformed_service_reverse_data[service];
        if (managedServiceList.includes(transformedService)) {
            getTrunks();
        }

        return () => {
            setCurrentTrunkId(null);
            unsetTrunksInited();
        }
    }, []);


    const getTrunks = (withNumbers=false) => {
        // if (
        //     Object.keys(account).length && account?.id === accountId 
        //     && transformed_service_data[account?.service] === service
        // ) {
            getAccountTrunk(accountId, service);
        // }

        if (Object.keys(numbersParams).length && withNumbers && currentTrunkId) {
            const {group, filter, page, per_page, sort} = numbersParams;

            getAccountAllocatedNumbers(accountId, currentTrunkId, service, group, filter, page, per_page, sort)
        }
    };
    

    const getNumbers = (group, filter, page, per_page, sort, first = false) => {
        if (currentTrunkId) {
            const params = {group, filter, page, per_page, sort};

            setNumbersParams(params);
            const curPerPage = per_page || DEFAULT_PER_PAGE_SMALL;
            getAccountAllocatedNumbers(accountId, currentTrunkId, service, group, filter, page, curPerPage, sort);
        }
    };

    const setTrunk = (trunk_id) => {
        if (allocatedNumbersLoading) return;

        const activeTrunk = trunks.find((trunk) => trunk.id === trunk_id);
        const numbersTableReadonly = activeTrunk ? activeTrunk.closed: true;

        setCurrentTrunkId(trunk_id);
        setNumbersTableReadonly(numbersTableReadonly);
    };

    const currentTrunk = trunks.find(trunk => trunk.id === currentTrunkId) || {};

    const columns = [
        {
            label: "Name",
            dataKey: "name",
            flexGrow: 1,
            minWidth: 180,
        },
        ...(service ? [{
            label: "Type",
            dataKey: "type",
            value: ({type, ivr_name, is_default_term_point}) => (is_default_term_point ? ivr_name : type),
            minWidth: 180,
        }] : []),
        {
            label: "Price",
            dataKey: "service_plan_name",
            minWidth: 115,
        },
        {
            id: "allocated_numbers",
            label: "Allocated Numbers",
            value: (({allocated_numbers, numbers_with_traffic}) => (
                `${allocated_numbers||0}/${numbers_with_traffic||0}`)
            ),
            align: "center",
            minWidth: 192,
            flexGrow: 2
        },
        ...(service ? [{
            label: "Billing increment",
            dataKey: "rrc_name",
            width: 150,
            align: "right"
        }] : []),
        ...(!service ? [{
            label: "Show message",
            value: ({full_message}) => {
                const iconType = !!full_message ? "check-circle" : "close-circle";
                // const color = !!full_message ? "green" : "red";
                return <Icon icon={iconType}/>
            },
            align: "center",
            minWidth: 102,
        }] : []),
        {
            label: "Start Date",
            dataKey: "start_date",
            formatData: "date",
            align: "right",
            minWidth: 96,
        },
        {
            label: "End Date",
            dataKey: "end_date",
            value: ({end_date}) => {
                if (!end_date)
                    return "";
    
                const endDate = new Date(end_date);
                const endDateObg = new Date( 
                    endDate.getFullYear(), 
                    endDate.getMonth(), 
                    endDate.getDate(), 
                    endDate.getHours(), 
                    endDate.getMinutes(), 
                    endDate.getSeconds() - 1 
                );
    
                return new Date(endDateObg).toLocaleDateString();
            },
            formatData: "date",
            align: "right",
            minWidth: 96,
        },
        ...(account.is_reseller && service ? [{
            label: "Reseller trunk",
            dataKey: "is_reseller",
            value: (({is_reseller}) => {
                return {
                    [true]: "Yes",
                    [false]: "No"
                }[is_reseller] || "";
            }),
            align: "center",
            width: 130,
        }] : [{}])
    ];

    const mobileColumns = [
        {
            id: "name",
            label: "Name",
            value: (({name, type, service_plan_name}) => {
                    return (service ? <div className={"table-two-staged__cell"}>
                        <div className="table-two-staged__cell-first">{name}</div>
                        <div className="table-two-staged__cell-second">{`${type}, ${service_plan_name}`}</div>
                    </div> : <div>{name}</div>)
                }
            ),
            minWidth: 150,
            flexGrow: 1
        },
    ];
    

    return (
        <>

            <Trunks
                {...{
                    ivr_list,
                    trunks,
                    columns,
                    service,
                    account,
                    isMobile,
                    mobileColumns,
                    trunksLoading,
                    currentTrunkId,
                    isSupervisorSms,
                    accountServicePlanList,
                    allocatedNumbersLoading,
                    trunkDownloadNumbers
                }}

                acc_key={accountId}
                trunk_id={currentTrunkId}
                account_name={account?.name}
                closedTrunksShow={showClosedTrunks}
                
                setTrunk={setTrunk}
                getTrunks={getTrunks}
                onAllocateNumbers={() => setShowAllocatedNumbersModal(true)}
                setCurrentTrunkId={setCurrentTrunkId}
                setClosedTrunksShow={() => setShowClosedTrunks(!showClosedTrunks)}
                setCurrentRangeNumber={setCurrentRangeNumber}
            />

            <Header>Allocated numbers</Header>

            <AllocatedNumbers
                {...{
                    activeTab,
                    account,
                    ivr_list,
                    service,
                    isSupervisorSms,
                    currentRangeNumber,
                    allocatedNumbers,
                    allocatedNumbersLoading,
                    allocatedNumbersCount,
                    allocatedNumbersTotalCount,
                    allocatedNumbersPage,
                    allocatedNumbersPerPage,
                    showAllocatedNumbersModal,
                    getTrunkNumberCsvById,
                    getIvrList,

                    trunkDownloadNumbers,

                    trunksLoading,
                    trunks,
                    trunksInited,
                    raterc_list
                }}
                trunk_id={currentTrunkId}
                closeAllocatedNumbersModal = {() => setShowAllocatedNumbersModal(false)}
                sp_key={currentTrunk.sp_key}
                sp_auth={currentTrunk.sp_auth}
                spOtpType={currentTrunk.sp_otp_type}
                acc_key={accountId}
                account_name={account.name}
                getNumbers={getNumbers}
                readonly={numbersTableReadonly}
                getTrunks={() => getTrunks(true)}
            />

        </>
    )
}